<template>
  <div>
    <div class="filter-container top50">
      <div style="color:red;font-size: 14px;">提示：印花税核定征收请直接填写核定比例，如核定比例为0，默认不是核定征收</div>
      <div>
        <qzf-video vid="4467be537466bcfba6353f4a1c7fa3d1_4"></qzf-video>
        
        <el-button type="primary" size="small" @click="addTax()">
          <el-icon>
            <CirclePlus />
          </el-icon><span  > 新增税种</span>
        </el-button>
        <!-- <el-button type="success" size="small" @click="getNew()">
          <el-icon><Pointer /></el-icon><span  > 税种更新</span>
        </el-button> -->
      </div>
    </div>
    <el-table :height="contentStyleObj" :key="tableKey" :data="listS" border style="width: 100%;"
      @sort-change="sortChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="序号" align="center" width="55" type="index">
      </el-table-column>
      <el-table-column label="征收项目" align="center" min-width="160">
        <template #default="{row}">
          <span>{{row.collectionProject}}</span>
        </template>
      </el-table-column>
      <el-table-column label="征收品目" align="center" min-width="150">
        <template #default="{row}">
          <div>
            <el-input placeholder="" v-model="row.collectionItems" class="filter-item" size="small"
              style="width: 130px" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="征收子目" align="center" min-width="150">
        <template #default="{row}">
          <span>{{row.collectionSub}}</span>
        </template>
      </el-table-column>
      <el-table-column label="申报周期" align="center" min-width="130">
        <template #default="{row}">
          <div>
            <!-- 这个地方改成多选的 但是 只限月份的 -->


            <el-select size="small" v-model="row.types" multiple collapse-tags collapse-tags-tooltip
              style="width: 90%">
              <el-option v-for="item in type" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

          </div>
        </template>
      </el-table-column>
      <el-table-column label="征收方式" align="center" min-width="120">
        <template #default="{row}">
          <span>{{row.collectionAgency}}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用时间" align="center" min-width="150">
        <template #default="{row}">
          <el-input placeholder="" v-model="row.validityPeriodStart" class="filter-item" size="small"
            style="width: 100px" />
        </template>
      </el-table-column>
      <el-table-column label="结束时间" align="center" min-width="150">
        <template #default="{row}">
          <el-input placeholder="" v-model="row.validityPeriodEnd" class="filter-item" size="small"
            style="width: 100px" />
        </template>
      </el-table-column>

      <el-table-column label="税率（小数）" align="center" min-width="120">
        <template #default="{row}">
          <el-input placeholder="" v-model="row.rate" class="filter-item" size="small"
            style="width: 70px;text-align: center;" />
        </template>
      </el-table-column>
      <el-table-column label="核定征收（印花）" min-width="180" align="center">
        <template #default="{row}">
          <div v-if="row.collectionProject == '印花税'">
            <span>核定比例(小数)</span>
            <span>
              <el-input placeholder="" type="number" v-model.number="row.yhHdbl" style="width: 100px"
                class="filter-item" size="small" />
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="启用状态" min-width="200" align="center">
        <template #default="{row}">
          <el-radio-group v-model="row.qyzt">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">不启用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="印花次报启用状态(如没有次报不生成报表)" min-width="300" align="center">
        <template #default="{row}">
          <div v-if="row.collectionProject == '印花税' && row.type == '次'">
            <el-radio-group v-model="row.yhscbqyzt">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">不启用</el-radio>
            </el-radio-group>
          </div>

        </template>
      </el-table-column>
      <el-table-column label="操作" width="130" align="center">
        <template #default="{row}">
          <i @click="taxSave(row)" class="iconfont icon-baocun"></i>
          <i @click="taxDel(row.id)" class="iconfont icon-shanchu"></i>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <addComInfo ref="addComInfo" @success="getList"></addComInfo>
</template>

<script>
import addComInfo from "./addComInfo.vue"
import { taxCategoryList, delOneTaxCategory, saveTaxCategory } from '@/api/taxCalculation.js'
export default {
  name: 'information',
  components: { addComInfo },
  data() {
    return {
      listS: [],
      type: [
        {
          value: '月',
          label: '月报'
        }, {
          value: '季',
          label: '季报'
        }, {
          value: '次',
          label: '次报'
        }, {
          value: '不申报',
          label: '不申报'
        }, {
          value: '01',
          label: '一月'
        }, {
          value: '02',
          label: '二月'
        }, {
          value: '03',
          label: '三月'
        }, {
          value: '04',
          label: '四月'
        }, {
          value: '05',
          label: '五月'
        }, {
          value: '06',
          label: '六月'
        }, {
          value: '07',
          label: '七月'
        }, {
          value: '08',
          label: '八月'
        }, {
          value: '09',
          label: '九月'
        }, {
          value: '10',
          label: '十月'
        }, {
          value: '11',
          label: '十一月'
        }, {
          value: '12',
          label: '十二月'
        }
      ],
      comId: ''
    }
  },
  created() {
    this.contentStyleObj = this.$getHeight(280)
    this.init()
  },
  methods: {
    init() {
      this.getList()
    },
    addTax() {
      this.$refs.addComInfo.openSalary(this.comId)
    },
    //税种信息列表
    getList() {
      // if(this.activeName == 'information') {
      this.comId = this.$store.getters["commons/params"].editCustomerComId * 1
      if (this.$store.getters["user/comInfo"].comId * 1) {
        this.comId = this.$store.getters["user/comInfo"].comId * 1
      } else if (this.$store.getters["commons/params"].editCustomerComId.id * 1) {
        this.comId = this.$store.getters["commons/params"].editCustomerComId.id
      }
      taxCategoryList({ comId: this.comId }).then(res => {
        if (res.data.data.list) {
          let data = res.data.data.list
          data.map(v=>{
            v.types = v.type.split(",")
          })
          this.listS = data
        } else {
          this.listS = []
        }
      })
      // }
    },
    //税种信息删除
    taxDel(id) {
      this.$confirm('是否删除此税种?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delOneTaxCategory({ id: id }).then(res => {
          if (res.data.msg == "success") {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.getList()
          }
        })
      })
    },
    taxSave(row) {
      row.type = row.types.join(",")
      saveTaxCategory(row).then(res => {
        if (res.data.msg == "success") {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.getList()
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  justify-content: space-between;
}

.icon-baocun,.icon-shanchu {
  margin: 0 5px;
  cursor: pointer;
  color: $color-main;
  font-size: 18px;
  line-height: 24px;
}

.el-radio {
  height: 30px;
}
</style>