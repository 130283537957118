<template>
  <div>
    <div class="common_dialog_box clearfix">
      <div style="float:right">
        <qzf-video vid="4467be537495bcad65a69d90d53acfa5_4"></qzf-video>
      </div>
      <div class="common_title">基本信息</div>
      <div class="item_one clearfix">
        <el-form :inline="true" style="width:100%" label-width="160px" class="com_info">
          <el-form-item label="企业名称">
            <el-input placeholder="企业名称" v-model="basic1.name" size="small"/>
          </el-form-item>
          <el-form-item label="纳税人识别号">
            <el-input placeholder="纳税人识别号" v-model="basic1.taxNo" size="small"/>
          </el-form-item>
          <el-form-item label="纳税人资格">
            <selecttaxtype :status="false"  v-model:type="basic1.type" @select="selects" type="tax_type"></selecttaxtype>
          </el-form-item>
          <el-form-item label="公司类型">
            <el-select v-model="basic1.companyType" placeholder="请选择公司类型" size="small">
              <el-option label="个体工商户" value="个体工商户" size="small">
              </el-option>
              <el-option label="个人独资企业" value="个人独资企业" size="small">
              </el-option>
              <el-option label="合伙企业" value="合伙企业" size="small">
              </el-option>
              <el-option label="农村合作社" value="农村合作社" size="small">
              </el-option>
              <el-option label="有限责任公司" value="有限责任公司" size="small">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="会计准则">
            <el-select v-model="basic1.accountSystem" placeholder="请选择会计准则" size="small" style="width:200px">
              <el-option label="小企业会计准则2013版" value="小企业会计准则2013版" size="small">
              </el-option>
              <el-option label="一般企业会计准则(未执行新金融准则)" value="一般企业会计准则" size="small">
              </el-option>
              <el-option label="一般企业会计准则(已执行新金融准则)" value="一般企业会计准则(已执行新金融准则)" size="small">
              </el-option>
              <el-option label="企业会计制度"  value="企业会计制度" size="small">
              </el-option>
              <el-option label="民间非盈利" value="民办非" size="small"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属税务总局">
            <selectcity v-model:citys="basic1.districtCode" @success="discodeChange()"></selectcity>
          </el-form-item>
          <el-form-item label="主管税务机关">
            <el-input placeholder="主管税务机关" v-model="basic1.taxNo" size="small"/>
          </el-form-item>
          <el-form-item label="启用账期">
            <span style="width:200px;display: inline-block;">
              {{basic1.startAccountPeriod}}
            </span>
          </el-form-item>
          <el-form-item label="所得税类型">
            <el-radio-group v-model="basic1.qysdsStatus">
              <el-radio :label="1">A类</el-radio>
              <el-radio :label="2">B类</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-form label-width="160px">
          <el-form-item label="公司地址">
            <el-input placeholder="公司地址" v-model="basic1.gsdz" size="small" style="width:90%"/>
          </el-form-item>
          <el-form-item label="经营范围">
            <el-input placeholder="经营范围" v-model="basic1.jyfw" type="textarea" style="width:90%" size="small"/>
          </el-form-item>
        </el-form>
      </div>
    </div>
      
    <div class="common_dialog_box clearfix">
      <div class="common_title">税局信息</div>
      <div class="item_one clearfix">
        <el-form :inline="true" style="width:100%" label-width="160px" class="com_info">
          <el-form-item v-if="userPass.loginMethod" label="税局登录方式">
            <el-select :disabled="!userPass.loginMethodDisabled" v-model="basic1.loginMethod" placeholder="请选择税局登录方式" size="small" @change="changeLoginMethod" style="width: 200px">
              <!-- <el-option v-for="item in tax_login" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
              <template v-if="userPass.loginMethodOption">
                <el-option v-for="item in userPass.loginMethodOption" :key="item" :label="item" :value="item"></el-option>
              </template>
              <template v-else>
                <el-option v-for="item in tax_login" :key="item.value" :label="item.value" :value="item.value"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item v-if="userPass.idNo" :label="userPass.idNoLabel">
            <el-input :placeholder="userPass.idNoText" v-model="basic1.idNo" size="small"/>
          </el-form-item>
          <el-form-item v-if="userPass.password" :label="userPass.passwordLabel">
            <el-input placeholder="税务密码" v-model="basic1.password" size="small"/>
          </el-form-item>
          <el-form-item label="个税登录方式">
            <el-select v-model="basic1.personalLoginType" placeholder="请选择个税登录方式" size="small" style="width: 200px" @change="changePersonalType">
              <el-option v-for="item in personal_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="userPass.personalIdno" label="个税实名登录账号">
            <el-input placeholder="个税实名登录账号" v-model="basic1.personalIdno" size="small"/>
          </el-form-item>
          <el-form-item v-if="userPass.personalTaxPwd" label="个税密码">
            <el-input placeholder="个税密码" v-model="basic1.personalTaxPwd" size="small"/>
          </el-form-item>
          <el-form-item label="选择身份" v-if="userPass.xzsf">
            <el-select v-model="basic1.xzsf" placeholder="选择身份" size="small">
              <el-option label="法人" value="法人"></el-option>
              <el-option label="财务负责人" value="财务负责人"></el-option>
              <el-option label="办税人" value="办税人"></el-option>
              <el-option label="购票员" value="购票员"></el-option>
              <el-option label="其他人员" value="其他人员"></el-option>
              <el-option label="附办税人" value="附办税人"></el-option>
              <el-option label="普通管理员" value="普通管理员"></el-option>
              <el-option label="实名办税" value="实名办税"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="userPass.jbrIdno" label="经办人">
            <el-input placeholder="经办人" v-model="basic1.jbr" size="small"/>
          </el-form-item>
          <el-form-item v-if="userPass.jbrIdno" label="经办人身份证号">  
            <el-input placeholder="经办人身份证号" v-model="basic1.jbrIdno" size="small"/>
          </el-form-item>
          <el-form-item v-if="userPass.zzrmn" :label="userPass.zzrmnLabel" >
            <el-input :placeholder="userPass.zzrmnText" v-model="basic1.zzrmn" size="small"/>
          </el-form-item>
          <el-form-item v-if="userPass.zzrxm" label="办税人/自然人姓名" >
            <el-input placeholder="办税人/自然人姓名" v-model="basic1.zzrxm" size="small"/>
          </el-form-item>
          <el-form-item  v-if="userPass.zzridno" label="办税人/自然人身份证号" >
            <el-input placeholder="办税人/自然人身份证号" v-model="basic1.zzridno" size="small"/>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="common_dialog_box clearfix">
      <div class="common_title">联系人</div>
      <div class="item_one clearfix">
        <el-form :inline="true" style="width:100%" label-width="160px" class="com_info">
          <el-form-item label="法人代表">
            <el-input placeholder="法人代表" v-model="basic1.manager" size="small"/>
          </el-form-item>
          <el-form-item label="身份证">
            <el-input placeholder="法人代表身份证" v-model="basic1.managerIdno" size="small"/>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input placeholder="法人代表联系电话" v-model="basic1.managerTel" size="small"/>
          </el-form-item>
        </el-form>

        <el-form :inline="true" style="width:100%" label-width="160px" class="com_info">
          <el-form-item label="财务负责人">
            <el-input placeholder="财务负责人" v-model="basic1.linkman" size="small"/>
          </el-form-item>
            <el-form-item label="身份证">
            <el-input placeholder="财务负责人身份证" v-model="basic1.linkmanIdno" size="small"/>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input placeholder="财务负责人联系电话" v-model="basic1.linkmanTel" size="small"/>
          </el-form-item>
        </el-form>

        <el-form :inline="true" style="width:100%" label-width="160px" class="com_info">
          <el-form-item label="办税人">
            <el-input placeholder="办税人" v-model="basic1.taxCollectorName" size="small"/>
          </el-form-item>
          <el-form-item label="身份证">
            <el-input placeholder="办税人身份证" v-model="basic1.taxCollectorIdno" size="small"/>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input placeholder="办税人联系电话" v-model="basic1.taxCollectorNameTel" size="small"/>
          </el-form-item>
        </el-form>
      </div>
    </div>
      
    <div class="common_dialog_box clearfix">
      <div class="common_title">基础配置</div>
      <div class="item_one clearfix">
        <el-form :inline="true" style="width:100%" label-width="160px" class="com_info">
          <el-form-item label="开启存货">
            <el-radio-group v-model="basic1.inventoryStatus">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="开启账期">
            <el-date-picker disabled size="small" v-model="basic1.inventoryTime" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYYMM"></el-date-picker>
          </el-form-item>

          <el-form-item label="辅助核算">
            <el-radio-group v-model="basic1.fzhsStatus">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="开启账期">
            <el-date-picker disabled size="small" v-model="basic1.fzhsTime" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYYMM"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- </el-form> -->
    <el-button @click="savebasic" type="primary" size="small" style="float:right" class="save_btn" :loading="loading">
      <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i><span> 保存修改</span>
    </el-button>


    
  </div>
</template>

<script>
import selecttaxtype from "@/components/Screening/selecttaxtype"
import selectcity from "@/components/Screening/selectcity"
import { setComSet , eaCompanyInfo ,saveEaCompany } from "@/api/company";
import { disCodeUserPass } from '@/utils'
export default {
  name:'basicInform',
  components: { selecttaxtype , selectcity },
  data() {
    return {
      listData:[],
      basic1: {
        jbr: '',
        jbrIdno: '',
        name: '',
        taxNo: '',
        districtCode: [],
        type: '',
        personalTaxPwd: "",
        linkman: '',
        linkmanTel: '',
        manager: '',
        managerTel: '',
      },
      value:'',
      radio:'',
      userPass:{},
      tax_login: [
        {
          label: "账号密码",
          value: "账号密码"
        },
        {
          label: "短信登录",
          value: "短信登录"
        },
        {
          label: "代理业务登录",
          value: "代理业务登录"
        }
      ],
      personal_type:[
        {
          value:'实名登录',
          label:'实名登录'
        },
        {
          value:'申报密码登录',
          label:'申报密码登录'
        }
      ],
      period:'',
      parma:{},
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(280)
    this.init()
  },
  methods:{
    init(){
      this.getList()
    },
    getList(){
      console.log(this.$store.getters)
      let comId = this.$store.getters["commons/params"].editCustomerComId * 1
      if(this.$store.getters["user/comInfo"].comId * 1){
        comId = this.$store.getters["user/comInfo"].comId * 1
        this.period = this.$store.getters["user/comInfo"].period
        this.parma = {
          comId,
          period:this.period
        }
      }else if(this.$store.getters["commons/params"].editCustomerComId.id *1){
        comId = this.$store.getters["commons/params"].editCustomerComId.id
        this.parma = {
          comId
        }
      }
      eaCompanyInfo(this.parma).then(res=>{
        this.basic1 = res.data.data.info
        this.userPass = disCodeUserPass(this.basic1.districtCode,this.basic1.loginMethod)
        this.changePersonalType()
      })
    },
    saveSetting(row){
      setComSet(row).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
        }
      })
    },
    // 选择个税登录方式时
    changePersonalType(){
      if (this.basic1.personalLoginType == "实名登录") {
        this.userPass.personalIdno = true;
      }else  {
        this.userPass.personalIdno = false;
      }
    },
    changeLoginMethod(){
      this.userPass = disCodeUserPass(this.basic1.districtCode,this.basic1.loginMethod)
    },
    savebasic(){
      this.basic1.comId = this.basic1.id
      saveEaCompany({list:[this.basic1]}).then(res=>{
        if(res.data.msg == "success"){
          if(res.data.data.fail_total){
            this.$qzfMessage(res.data.data.fail_list[0].remark,2)
          }else{
            this.$qzfMessage("保存成功")
            this.getList()
          }
         
        }
      })
    },
    // 选择地区时
    discodeChange(type){
      this.basic1.loginMethod = ''
      this.userPass = disCodeUserPass(this.basic1.districtCode,this.basic1.loginMethod)
      this.basic1.loginMethod = this.userPass.loginMethod
      if (this.basic1.personalLoginType == "实名登录") {
          this.userPass.personalIdno = true;
      }else  {
          this.userPass.personalIdno = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.com_info .el-select .el-input__wrapper{
  margin: 0 0 0 0;
}
.com_info {
  :deep(.el-input__wrapper) {
    width:200px
  }
}
.com_info .el-input{
  margin: 0 0 0 0;
}

.bottom_tax .el-table .cell{
  line-height: 23px !important;
}
.bottom_tax{
  width: 100%;
  margin-top: 20px;
}
</style>

<style lang="scss" scoped>
.common_dialog_box{
  width: 100%;
  margin: 0 auto;
}
.common_dialog_box .common_title{
  font-size: 14px;
  color: $color-main;
  font-weight: 600;
  line-height: 36px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}
.common_dialog_box .common_title span{
  color: $color-main;
  margin-left: 30px;
  font-weight: normal !important;
}
.common_dialog_box  .item{
  // width: 46%;
  // margin-bottom:20px
}
.common_dialog_box  .item:nth-child(2n+1){
  margin-left: 0;
}
.new_dialog .el-button--primary{
  background: $color-main;
  border-color: $color-main;
}
.common_dialog_box .el-select{
  margin:0 0 0 0 !important;
  width:100%
}
</style>
<style lang="scss">
.com_info .el-input__inner{
  margin: 0 0 0 0;
  // width:200px
}
</style>