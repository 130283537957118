<template>
  <div>
    <el-button type="primary" size="small" @click="handleClick()" style="margin-bottom:10px">绑定</el-button>

    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="name" label="名称" min-width="180" />
      <el-table-column prop="tel" label="手机号" min-width="180" />
    </el-table>

    <el-dialog :close-on-click-modal="false"
      v-model="dialogVisible"
      title="绑定"
      width="30%"
      :before-close="handleClose"
    >
    <el-table :data="userList" style="width: 100%" border @selection-change="handleSelectionChange">
      <el-table-column align="center" prop="id" type="selection" min-width="55" />
      <el-table-column prop="nickname" label="名称" width="180" />
      <el-table-column prop="tel" label="手机号" width="180" />
    </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="bindUser"> 确认 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getBindWxUser,searchWxUser,comBindUser } from '@/api/company'
export default {
  name: 'EtaxWebV2BindApplet',

  data() {
    return {
      tableData:[
      ],
      userList:[],
      dialogVisible:false,
      comId:'',
      ids:[]
    };
  },
  created(){
    this.getList()
  },

  mounted() {
    
  },

  methods: {
    getList(){
      let comId = this.$store.getters["commons/params"].editCustomerComId * 1
      if(this.$store.getters["user/comInfo"].comId * 1){
        comId = this.$store.getters["user/comInfo"].comId * 1
      }else if(this.$store.getters["commons/params"].editCustomerComId.id *1){
        comId = this.$store.getters["commons/params"].editCustomerComId.id
      }
      this.comId = comId
      getBindWxUser({comId}).then(res => {
        if(res.data.data.list.length != 0){
            this.tableData = res.data.data.list
          }else{
            this.tableData = []
          }
      })
    },
    handleClick(row){
      this.$prompt('请输入手机号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /(^1\d{10}$)|(^[0-9]\d{7}$)/,
        inputErrorMessage: '手机号格式不正确'
      }).then(({ value }) => {
        searchWxUser({tel:value}).then(res => {
          if(res.data.data.list.length != 0){
            this.userList = res.data.data.list
            this.dialogVisible = true
          }else{
            this.$qzfMessage("暂无数据",1)
          }
        })
      })
    },
    handleSelectionChange(val) {
      this.ids = []
      val.map(v=>{
        this.ids.push({
          userId:v.id,
          comId:this.comId
        })
      })
      console.log(this.ids);
    },
    bindUser(){
      comBindUser(this.ids).then(res => {
        console.log(res);
        if(res.data.msg == 'success'){
          this.dialogVisible = false
          this.$qzfMessage("绑定成功")
          this.getList()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

</style>